import i18n from 'i18next';

import { Translate } from '../../components/translate';

export const DROPOUT_REASON_NAMES = {
  DEFECT: 'Брак',
  EXPIRY: 'Истек срок годности',
  QA_SAMPLES: 'Лабораторные образцы',
  PRODUCT_RECALL: 'Отзыв с рынка',
  COMPLAINTS: 'Рекламации',
  PRODUCT_TESTING: 'Тестирование продукта',
  DEMO_SAMPLES: 'Демонстрационные образцы',
  DESTRUCTION: 'Уничтожение товаров',
  EXPORT: 'Экспорт',
  OTHER: 'Другие причины',
};

export const DROPOUT_REASONS = [
  { value: 'DEFECT', label: Translate(DROPOUT_REASON_NAMES.DEFECT) },
  { value: 'EXPIRY', label: Translate(DROPOUT_REASON_NAMES.EXPIRY) },
  { value: 'QA_SAMPLES', label: Translate(DROPOUT_REASON_NAMES.QA_SAMPLES) },
  {
    value: 'PRODUCT_RECALL',
    label: Translate(DROPOUT_REASON_NAMES.PRODUCT_RECALL),
  },
  { value: 'COMPLAINTS', label: Translate(DROPOUT_REASON_NAMES.COMPLAINTS) },
  {
    value: 'PRODUCT_TESTING',
    label: Translate(DROPOUT_REASON_NAMES.PRODUCT_TESTING),
  },
  {
    value: 'DEMO_SAMPLES',
    label: Translate(DROPOUT_REASON_NAMES.DEMO_SAMPLES),
  },
  { value: 'DESTRUCTION', label: Translate(DROPOUT_REASON_NAMES.DESTRUCTION) },
  { value: 'EXPORT', label: Translate(DROPOUT_REASON_NAMES.EXPORT) },
  { value: 'OTHER', label: Translate(DROPOUT_REASON_NAMES.OTHER) },
];

export const headers = [
  {
    accessorKey: 'code',
    id: 'code',
    header: i18n.t('КМ агрегата'),
    enableSorting: false,
  },
];
