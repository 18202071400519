import React from 'react';

import { useSelector } from 'react-redux';

import { getLocalizedTheme, Theme as IBOXTheme } from '@ibox/ui';

import { setFavicon } from '../../helpers';

import './index.scss';

export const Theme = ({ children }) => {
  const countryCode = useSelector((state) => state.settings.common.countryCode);
  const language = useSelector((state) => state.settings.language);

  React.useEffect(() => {
    setFavicon(countryCode);
    const body = document.getElementById('body');
    if (body) {
      body.className = `theme-${countryCode.toLowerCase()}`;
    }
  }, [countryCode]);

  const theme = React.useMemo(
    () => getLocalizedTheme(countryCode, language),
    [countryCode, language]
  );

  return <IBOXTheme theme={theme}>{children}</IBOXTheme>;
};
