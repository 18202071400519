import { TFunction } from 'i18next';

export const getCountryOptions = (t: TFunction) => [
  { label: t('АНДОРРА'), value: 'AD' },
  { label: t('МАРТИНИКА'), value: 'MQ' },
  { label: t('СЛОВЕНИЯ'), value: 'SI' },
  { label: t('КОСТА-РИКА'), value: 'CR' },
  { label: t('ВЕНГРИЯ'), value: 'HU' },
  { label: t('ШПИЦБЕРГЕН И ЯН МАЙЕН'), value: 'SJ' },
  { label: t('ОБЪЕДИНЕННЫЕ АРАБСКИЕ ЭМИРАТЫ'), value: 'AE' },
  { label: t('МАВРИТАНИЯ'), value: 'MR' },
  { label: t('КУБА'), value: 'CU' },
  { label: t('СЛОВАКИЯ'), value: 'SK' },
  { label: t('АФГАНИСТАН'), value: 'AF' },
  { label: t('ИНДОНЕЗИЯ'), value: 'ID' },
  { label: t('МОНТСЕРРАТ'), value: 'MS' },
  { label: t('КАБО-ВЕРДЕ'), value: 'CV' },
  { label: t('СЬЕРРА-ЛЕОНЕ'), value: 'SL' },
  { label: t('АНТИГУА И БАРБУДА'), value: 'AG' },
  { label: t('ИРЛАНДИЯ'), value: 'IE' },
  { label: t('МАЛЬТА'), value: 'MT' },
  { label: t('КЮРАСАО'), value: 'CW' },
  { label: t('САН-МАРИНО'), value: 'SM' },
  { label: t('АНГИЛЬЯ'), value: 'AI' },
  { label: t('ИЗРАИЛЬ'), value: 'IL' },
  { label: t('МАВРИКИЙ'), value: 'MU' },
  { label: t('ОСТРОВ РОЖДЕСТВА'), value: 'CX' },
  { label: t('АЛБАНИЯ'), value: 'AL' },
  { label: t('СЕНЕГАЛ'), value: 'SN' },
  { label: t('ОСТРОВ МЭН'), value: 'IM' },
  { label: t('МАЛЬДИВЫ'), value: 'MV' },
  { label: t('КИПР'), value: 'CY' },
  { label: t('АРМЕНИЯ'), value: 'AM' },
  { label: t('СОМАЛИ'), value: 'SO' },
  { label: t('ИНДИЯ'), value: 'IN' },
  { label: t('МАЛАВИ'), value: 'MW' },
  { label: t('СУРИНАМ'), value: 'SR' },
  { label: t('БРИТАНСКАЯ ТЕРРИТОРИЯ В ИНДИЙСКОМ ОКЕАНЕ'), value: 'IO' },
  { label: t('МЕКСИКА'), value: 'MX' },
  { label: t('ГЕРМАНИЯ'), value: 'DE' },
  { label: t('АНГОЛА'), value: 'AO' },
  { label: t('ЮЖНЫЙ СУДАН'), value: 'SS' },
  { label: t('ИРАК'), value: 'IQ' },
  { label: t('МАЛАЙЗИЯ'), value: 'MY' },
  { label: t('ДЖИБУТИ'), value: 'DJ' },
  { label: t('АНТАРКТИДА'), value: 'AQ' },
  { label: t('САН-ТОМЕ И ПРИНСИПИ'), value: 'ST' },
  { label: t('ИРАН (ИСЛАМСКАЯ РЕСПУБЛИКА)'), value: 'IR' },
  { label: t('МОЗАМБИК'), value: 'MZ' },
  { label: t('ДАНИЯ'), value: 'DK' },
  { label: t('АРГЕНТИНА'), value: 'AR' },
  { label: t('ЭЛЬ-САЛЬВАДОР'), value: 'SV' },
  { label: t('ИСЛАНДИЯ'), value: 'IS' },
  { label: t('НАМИБИЯ'), value: 'NA' },
  { label: t('ДОМИНИКА'), value: 'DM' },
  { label: t('АМЕРИКАНСКОЕ САМОА'), value: 'AS' },
  { label: t('СЕН-МАРТЕН (нидерландская часть)'), value: 'SX' },
  { label: t('ИТАЛИЯ'), value: 'IT' },
  { label: t('НОВАЯ КАЛЕДОНИЯ'), value: 'NC' },
  { label: t('ДОМИНИКАНСКАЯ РЕСПУБЛИКА'), value: 'DO' },
  { label: t('АВСТРИЯ'), value: 'AT' },
  { label: t('СИРИЙСКАЯ АРАБСКАЯ РЕСПУБЛИКА'), value: 'SY' },
  { label: t('ДЖЕРСИ'), value: 'JE' },
  { label: t('НИГЕР'), value: 'NE' },
  { label: t('АЛЖИР'), value: 'DZ' },
  { label: t('АВСТРАЛИЯ'), value: 'AU' },
  { label: t('ОСТРОВ НОРФОЛК'), value: 'NF' },
  { label: t('ЯМАЙКА'), value: 'JM' },
  { label: t('ЭКВАДОР'), value: 'EC' },
  { label: t('АРУБА'), value: 'AW' },
  { label: t('ОСТРОВА ТЕРКС И КАЙКОС'), value: 'TC' },
  { label: t('НИГЕРИЯ'), value: 'NG' },
  { label: t('ИОРДАНИЯ'), value: 'JO' },
  { label: t('ЭСТОНИЯ'), value: 'EE' },
  { label: t('ЭЛАНДСКИЕ ОСТРОВА'), value: 'AX' },
  { label: t('ЧАД'), value: 'TD' },
  { label: t('НИКАРАГУА'), value: 'NI' },
  { label: t('ЯПОНИЯ'), value: 'JP' },
  { label: t('ЕГИПЕТ'), value: 'EG' },
  { label: t('АЗЕРБАЙДЖАН'), value: 'AZ' },
  { label: t('ФРАНЦУЗСКИЕ ЮЖНЫЕ ТЕРРИТОРИИ'), value: 'TF' },
  { label: t('НИДЕРЛАНДЫ'), value: 'NL' },
  { label: t('КЕНИЯ'), value: 'KE' },
  { label: t('ЗАПАДНАЯ САХАРА'), value: 'EH' },
  { label: t('БОСНИЯ И ГЕРЦЕГОВИНА'), value: 'BA' },
  { label: t('ТОГО'), value: 'TG' },
  { label: t('НОРВЕГИЯ'), value: 'NO' },
  { label: t('ЭРИТРЕЯ'), value: 'ER' },
  { label: t('БАРБАДОС'), value: 'BB' },
  { label: t('ТАИЛАНД'), value: 'TH' },
  { label: t('НЕПАЛ'), value: 'NP' },
  { label: t('КАМБОДЖА'), value: 'KH' },
  { label: t('ИСПАНИЯ'), value: 'ES' },
  { label: t('БАНГЛАДЕШ'), value: 'BD' },
  { label: t('ТАДЖИКИСТАН'), value: 'TJ' },
  { label: t('НАУРУ'), value: 'NR' },
  { label: t('КИРИБАТИ'), value: 'KI' },
  { label: t('ЭФИОПИЯ'), value: 'ET' },
  { label: t('БЕЛЬГИЯ'), value: 'BE' },
  { label: t('ТОКЕЛАУ'), value: 'TK' },
  { label: t('НИУЭ'), value: 'NU' },
  { label: t('КОМОРЫ'), value: 'KM' },
  { label: t('ФИНЛЯНДИЯ'), value: 'FI' },
  { label: t('БУРКИНА-ФАСО'), value: 'BF' },
  { label: t('ТИМОР-ЛЕСТЕ'), value: 'TL' },
  { label: t('НОВАЯ ЗЕЛАНДИЯ'), value: 'NZ' },
  { label: t('СЕНТ-КИТС И НЕВИС'), value: 'KN' },
  { label: t('ФИДЖИ'), value: 'FJ' },
  { label: t('БОЛГАРИЯ'), value: 'BG' },
  { label: t('ОМАН'), value: 'OM' },
  { label: t('КОРЕЯ (НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА)'), value: 'KP' },
  { label: t('ФОЛКЛЕНДСКИЕ ОСТРОВА (МАЛЬВИНСКИЕ)'), value: 'FK' },
  { label: t('БАХРЕЙН'), value: 'BH' },
  { label: t('ТУНИС'), value: 'TN' },
  { label: t('ПАНАМА'), value: 'PA' },
  { label: t('КОРЕЯ'), value: 'KR' },
  { label: t('МИКРОНЕЗИЯ (ФЕДЕРАТИВНЫЕ ШТАТЫ)'), value: 'FM' },
  { label: t('БУРУНДИ'), value: 'BI' },
  { label: t('ТОНГА'), value: 'TO' },
  { label: t('ПЕРУ'), value: 'PE' },
  { label: t('КУВЕЙТ'), value: 'KW' },
  { label: t('ФАРЕРСКИЕ ОСТРОВА'), value: 'FO' },
  { label: t('БЕНИН'), value: 'BJ' },
  { label: t('ТУРЦИЯ'), value: 'TR' },
  { label: t('ФРАНЦУЗСКАЯ ПОЛИНЕЗИЯ'), value: 'PF' },
  { label: t('ОСТРОВА КАЙМАН'), value: 'KY' },
  { label: t('ФРАНЦИЯ'), value: 'FR' },
  { label: t('СЕН-БАРТЕЛЕМИ'), value: 'BL' },
  { label: t('ТРИНИДАД И ТОБАГО'), value: 'TT' },
  { label: t('ПАПУА НОВАЯ ГВИНЕЯ'), value: 'PG' },
  { label: t('КАЗАХСТАН'), value: 'KZ' },
  { label: t('ГАБОН'), value: 'GA' },
  { label: t('БЕРМУДЫ'), value: 'BM' },
  { label: t('ТУВАЛУ'), value: 'TV' },
  { label: t('ФИЛИППИНЫ'), value: 'PH' },
  { label: t('ЛАОССКАЯ НАРОДНО-ДЕМОКРАТИЧ. РЕСПУБЛИКА'), value: 'LA' },
  { label: t('СОЕДИНЕННОЕ КОРОЛЕВСТВО'), value: 'GB' },
  { label: t('БРУНЕЙ-ДАРУССАЛАМ'), value: 'BN' },
  { label: t('ТАЙВАНЬ (КИТАЙ)'), value: 'TW' },
  { label: t('ПАКИСТАН'), value: 'PK' },
  { label: t('ЛИВАН'), value: 'LB' },
  { label: t('ГРЕНАДА'), value: 'GD' },
  { label: t('БОЛИВИЯ (МНОГОНАЦИОНАЛЬНОЕ ГОСУДАРСТВО)'), value: 'BO' },
  { label: t('ТАНЗАНИЯ'), value: 'TZ' },
  { label: t('ПОЛЬША'), value: 'PL' },
  { label: t('СЕНТ-ЛЮСИЯ'), value: 'LC' },
  { label: t('ГРУЗИЯ'), value: 'GE' },
  { label: t('БОНЭЙР'), value: 'BQ' },
  { label: t('УКРАИНА'), value: 'UA' },
  { label: t('УЗБЕКИСТАН'), value: 'UZ' },
  { label: t('СЕНТ-ПЬЕР И МИКЕЛОН'), value: 'PM' },
  { label: t('ЛИХТЕНШТЕЙН'), value: 'LI' },
  { label: t('ФРАНЦУЗСКАЯ ГВИАНА'), value: 'GF' },
  { label: t('БРАЗИЛИЯ'), value: 'BR' },
  { label: t('УГАНДА'), value: 'UG' },
  { label: t('ПИТКЭРН'), value: 'PN' },
  { label: t('ШРИ-ЛАНКА'), value: 'LK' },
  { label: t('ГЕРНСИ'), value: 'GG' },
  { label: t('БАГАМЫ'), value: 'BS' },
  { label: t('ПУЭРТО-РИКО'), value: 'PR' },
  { label: t('ЛИБЕРИЯ'), value: 'LR' },
  { label: t('ГАНА'), value: 'GH' },
  { label: t('БУТАН'), value: 'BT' },
  { label: t('СОЕДИНЕННЫЕ ШТАТЫ'), value: 'US' },
  { label: t('ПАЛЕСТИНА'), value: 'PS' },
  { label: t('ЛЕСОТО'), value: 'LS' },
  { label: t('ГИБРАЛТАР'), value: 'GI' },
  { label: t('ОСТРОВ БУВЕ'), value: 'BV' },
  { label: t('УРУГВАЙ'), value: 'UY' },
  { label: t('ПОРТУГАЛИЯ'), value: 'PT' },
  { label: t('ЛИТВА'), value: 'LT' },
  { label: t('ГРЕНЛАНДИЯ'), value: 'GL' },
  { label: t('БОТСВАНА'), value: 'BW' },
  { label: t('ПАЛАУ'), value: 'PW' },
  { label: t('ЛЮКСЕМБУРГ'), value: 'LU' },
  { label: t('ГАМБИЯ'), value: 'GM' },
  { label: t('БЕЛАРУСЬ'), value: 'BY' },
  { label: t('ПАРАГВАЙ'), value: 'PY' },
  { label: t('ЛАТВИЯ'), value: 'LV' },
  { label: t('ГВИНЕЯ'), value: 'GN' },
  { label: t('БЕЛИЗ'), value: 'BZ' },
  { label: t('СЕНТ-ВИНСЕНТ И ГРЕНАДИНЫ'), value: 'VC' },
  { label: t('КАТАР'), value: 'QA' },
  { label: t('ЛИВИЯ'), value: 'LY' },
  { label: t('ГВАДЕЛУПА'), value: 'GP' },
  { label: t('КАНАДА'), value: 'CA' },
  { label: t('ВЕНЕСУЭЛА (БОЛИВАРИАНСКАЯ РЕСПУБЛИКА)'), value: 'VE' },
  { label: t('РЕЮНЬОН'), value: 'RE' },
  { label: t('МАРОККО'), value: 'MA' },
  { label: t('ЭКВАТОРИАЛЬНАЯ ГВИНЕЯ'), value: 'GQ' },
  { label: t('КОКОСОВЫЕ (КИЛИНГ) ОСТРОВА'), value: 'CC' },
  { label: t('ВИРГИНСКИЕ ОСТРОВА (БРИТАНСКИЕ)'), value: 'VG' },
  { label: t('РУМЫНИЯ'), value: 'RO' },
  { label: t('МОНАКО'), value: 'MC' },
  { label: t('ГРЕЦИЯ'), value: 'GR' },
  { label: t('КОНГО, ДЕМОКРАТИЧЕСКАЯ РЕСПУБЛИКА'), value: 'CD' },
  { label: t('ВИРГИНСКИЕ ОСТРОВА (США)'), value: 'VI' },
  { label: t('СЕРБИЯ'), value: 'RS' },
  { label: t('МОЛДОВА'), value: 'MD' },
  { label: t('ЦЕНТРАЛЬНО-АФРИКАНСКАЯ РЕСПУБЛИКА'), value: 'CF' },
  { label: t('ВЬЕТНАМ'), value: 'VN' },
  { label: t('РОССИЯ'), value: 'RU' },
  { label: t('ЧЕРНОГОРИЯ'), value: 'ME' },
  { label: t('ГВАТЕМАЛА'), value: 'GT' },
  { label: t('КОНГО'), value: 'CG' },
  { label: t('ВАНУАТУ'), value: 'VU' },
  { label: t('РУАНДА'), value: 'RW' },
  { label: t('ГУАМ'), value: 'GU' },
  { label: t('ШВЕЙЦАРИЯ'), value: 'CH' },
  { label: t('УОЛЛИС И ФУТУНА'), value: 'WF' },
  { label: t('САУДОВСКАЯ АРАВИЯ'), value: 'SA' },
  { label: t('МАДАГАСКАР'), value: 'MG' },
  { label: t('ГВИНЕЯ-БИСАУ'), value: 'GW' },
  { label: t("КОТ Д'ИВУАР"), value: 'CI' },
  { label: t('САМОА'), value: 'WS' },
  { label: t('СОЛОМОНОВЫ ОСТРОВА'), value: 'SB' },
  { label: t('МАРШАЛЛОВЫ ОСТРОВА'), value: 'MH' },
  { label: t('ГАЙАНА'), value: 'GY' },
  { label: t('ОСТРОВА КУКА'), value: 'CK' },
  { label: t('ЙЕМЕН'), value: 'YE' },
  { label: t('СЕЙШЕЛЫ'), value: 'SC' },
  { label: t('РЕСПУБЛИКА МАКЕДОНИЯ'), value: 'MK' },
  { label: t('ГОНКОНГ'), value: 'HK' },
  { label: t('ЧИЛИ'), value: 'CL' },
  { label: t('МАЙОТТА'), value: 'YT' },
  { label: t('СУДАН'), value: 'SD' },
  { label: t('МАЛИ'), value: 'ML' },
  { label: t('ОСТРОВ ХЕРД И ОСТРОВА МАКДОНАЛЬД'), value: 'HM' },
  { label: t('КАМЕРУН'), value: 'CM' },
  { label: t('ЮЖНАЯ АФРИКА'), value: 'ZA' },
  { label: t('ШВЕЦИЯ'), value: 'SE' },
  { label: t('МЬЯНМА'), value: 'MM' },
  { label: t('ГОНДУРАС'), value: 'HN' },
  { label: t('КИТАЙ'), value: 'CN' },
  { label: t('ЗАМБИЯ'), value: 'ZM' },
  { label: t('СИНГАПУР'), value: 'SG' },
  { label: t('МОНГОЛИЯ'), value: 'MN' },
  { label: t('ХОРВАТИЯ'), value: 'HR' },
  { label: t('КОЛУМБИЯ'), value: 'CO' },
  { label: t('ЗИМБАБВЕ'), value: 'ZW' },
  { label: t('СВ. ЕЛЕНА'), value: 'SH' },
  { label: t('МАКАО'), value: 'MO' },
  { label: t('ГАИТИ'), value: 'HT' },
  { label: t('СЕВЕРНЫЕ МАРИАНСКИЕ ОСТРОВА'), value: 'MP' },
  { label: t('КЫРГЫЗСТАН'), value: 'KG' },
  { label: t('ЧЕХИЯ'), value: 'CZ' },
  { label: t('ЮЖН. ДЖОРДЖИЯ И ЮЖН. САНДВИЧ. ОСТРОВА'), value: 'GS' },
  { label: t('СЕН-МАРТЕН (ФРАНЦУЗСКАЯ ЧАСТЬ)'), value: 'MF' },
  { label: t('ЭСВАТИНИ'), value: 'SZ' },
  { label: t('ТУРКМЕНИСТАН'), value: 'TM' },
  { label: t('МАЛЫЕ ТИХООКЕАН. ОТДАЛЕН. ОСТ-ВА С.Ш.'), value: 'UM' },
  { label: t('ПАПСКИЙ ПРЕСТОЛ (ГОС.-ГОРОД ВАТИКАН)'), value: 'VA' },
];
